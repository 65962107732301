/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { styled } from '@material-ui/core';
import React from 'react';

import { Download, DownloadProps } from 'components/partials/Download';
import { Container } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Flex = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
  },
}));

const Overflow = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    overflow: 'visible',
  },
}));

const InnerContainer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  borderRadius: theme.borderRadius[2],
  padding: theme.spacing(10, 6),
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    padding: theme.spacing(14, 15),
  },
}));

const Col = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    width: '50%',
  },
}));

const ColImage = styled(Col)(({ theme }) => ({
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
    width: '50%',
  },
}));

const DownloadSection: React.FC<{
  content: DownloadProps;
  showLinkToBeta?: boolean;
}> = ({ content, showLinkToBeta }) => {
  return (
    <Overflow>
      <Container>
        <InnerContainer>
          <Download
            content={content.content}
            showLinkToBeta={showLinkToBeta}
            render={({ content, mobileButtons, images }) => {
              return (
                <>
                  <Flex>
                    <ColImage>{images}</ColImage>
                    <Col>{content}</Col>
                  </Flex>
                  {mobileButtons}
                </>
              );
            }}
          />
        </InnerContainer>
      </Container>
    </Overflow>
  );
};

export { DownloadSection };
