/*
 * Copyright (C) 2024 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, useTheme } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import {
  Card as CardDefault,
  Col,
  Container,
  Illustration,
  TitleHighlight,
  Typography,
  Wrapper as WrapperDefault,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

const TitleContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(0),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(20),
  },
}));

const HeroTitle = styled(TitleHighlight)(({ theme }) => ({
  '& span': {
    display: 'inline-block',
    [theme.breakpoints.down(Breakpoints.Md)]: {
      display: 'block !important',
      margin: 'auto',
      width: 'max-content',
    },
  },

  lineHeight: theme.typography.pxToRem(16),
  marginBottom: theme.spacing(20),
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: theme.spacing(200),
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Xs)]: {
    '& span': {
      fontSize: theme.typography.pxToRem(39),
      transform: `translateY(${theme.typography.pxToRem(5)})`,
    },
  },

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& span': {
      fontSize: theme.typography.pxToRem(88),
    },
    ...theme.typography.h1,
    marginBottom: theme.spacing(7),
  },
}));

const Wrapper = styled(WrapperDefault)({
  display: 'flex',
  flexDirection: 'column',
});

const Card = styled(CardDefault)(({ theme }) => ({
  backgroundSize: theme.typography.pxToRem(335),
  padding: theme.spacing(6),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    backgroundSize: '46vw',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(10),
  },
}));

const CardContent = styled('div')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    alignSelf: 'center',
    maxWidth: '45%',
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h4,
  },

  [theme.breakpoints.up(Breakpoints.Lg)]: {
    ...theme.typography.h3,
  },
}));

const CustomIllustration = styled(Illustration)(({ theme }) => ({
  '& .asset': {
    bottom: 0,
    left: 0,
    width: '110%',
  },
  marginBottom: '5%',
  marginLeft: '5%',
  marginRight: '15%',
  marginTop: 'min(-10%, -60px)',
  width: '90%',
  [theme.breakpoints.up(Breakpoints.Md)]: {
    width: '50%',
  },
}));

const Hero: React.FC<HeroProps> = ({ content }) => {
  const theme = useTheme();
  return (
    <>
      <TitleContainer>
        <HeroTitle as="h1" variant="h4">
          {content.title}
        </HeroTitle>
      </TitleContainer>

      <Container>
        <Col xs={12}>
          <Wrapper>
            <Card bgColor={theme.palette.error.main}>
              <CustomIllustration
                alt=""
                objectPosition="center"
                image={content.image.childImageSharp.gatsbyImageData}
              />
              <CardContent>
                <CardTitle variant="body1">{content.description}</CardTitle>
              </CardContent>
            </Card>
          </Wrapper>
        </Col>
      </Container>
    </>
  );
};

export interface HeroProps {
  content: {
    title: string;
    description: string;
    image: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
  };
}

const query = graphql`
  fragment HeroDettyDecember on DettyDecemberYaml {
    hero {
      title
      description
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

export { Hero, query };
