/*
 * Copyright (C) 2024 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import { Promoter, PromoterCard } from './PromoterCard';
import { Container, TitleHighlight } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Title = styled(TitleHighlight)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(10),
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '200px',
  textAlign: 'center',
  textSize: theme.typography.pxToRem(20),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h3,
    marginBottom: theme.spacing(20),
    maxWidth: '400px',
  },
}));

const Layout = styled('div')(({ theme }) => ({
  /////// Grid ///////
  '& > *': {
    gridColumn: 'span 2',
  },
  '& > :first-child': {
    gridColumn: '2 / span 2',
  },
  display: 'grid',
  gap: theme.spacing(10, 0),
  gridTemplateColumns: 'repeat(4, 1fr)',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Sm)]: {
    '& > *': {
      width: '30%',
    },
    alignContent: 'space-around',
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(15, 0),
    justifyContent: 'center',
  },
}));

export interface PromotersProps {
  content: {
    title: string;
    list: Promoter[];
  };
}

const Promoters: React.FC<PromotersProps> = ({ content }) => {
  return (
    <>
      <Container>
        <Title as="h3">{content.title}</Title>
      </Container>

      <Container>
        <Layout>
          {content.list.map((info) => (
            <PromoterCard info={info} key={info.name} />
          ))}
        </Layout>
      </Container>
    </>
  );
};

const query = graphql`
  fragment PromotersDettyDecember on DettyDecemberYaml {
    promoters {
      title
      list {
        name
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        link
      }
    }
  }
`;

export { Promoters, query };
