/*
 * Copyright (C) 2024 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { Illustration, Typography } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const Wrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
}));

const ImageWrapper = styled('div')(({ theme }) => ({
  maxWidth: '70px',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: '170px',
  },
}));

const MaybeLink: React.FC<React.PropsWithChildren<{ link?: string }>> = ({
  link,
  children,
}) => {
  if (!link) return <>{children}</>;

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      style={{ display: 'block', width: '100%' }}
    >
      {children}
    </a>
  );
};

const PromoterPhoto = styled(Illustration)(() => ({
  '& .main-image': {
    borderRadius: '50%',
  },
}));

const Name = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 700,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.pxToRem(24),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontStyle: 'italic',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.pxToRem(22),
  },
}));

interface Promoter {
  name: string;
  title: string;
  subtitle?: string;
  link?: string;
  image: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
}

const PromoterCard: React.FC<{ info: Promoter }> = ({ info }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <MaybeLink link={info.link}>
          <PromoterPhoto
            alt={`Photo of ${info.name}`}
            image={info.image.childImageSharp.gatsbyImageData}
          />
        </MaybeLink>
      </ImageWrapper>

      <Name>{info.name}</Name>
      <Title>{info.title}</Title>
      {info.subtitle && <Title>{info.subtitle}</Title>}
    </Wrapper>
  );
};

export { type Promoter, PromoterCard };
