/*
 * Copyright (C) 2024 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMutation } from '@apollo/client';
import { styled, useTheme } from '@material-ui/core';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {
  Button as ButtonDefault,
  Card as CardDefault,
  Col,
  Container,
  Form,
  FormInput,
  Typography,
  Wrapper as WrapperDefault,
} from 'components/UI';
import { RECAPTCHA_SITE_KEY } from 'utils/constants';
import { Breakpoints } from 'utils/enums';

const Wrapper = styled(WrapperDefault)({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const Card = styled(CardDefault)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  maxWidth: '850px',
  textAlign: 'center',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    gap: theme.spacing(8),
  },
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h3,
  },
}));

const FormWrapper = styled('div')(({ theme }) => ({
  textAlign: 'start',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    width: '450px',
  },
}));

const Button = styled(ButtonDefault)(({ theme }) => ({
  paddingInline: theme.spacing(10),
  textTransform: 'uppercase',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.pxToRem(24),
    padding: theme.spacing(2, 24),
  },
}));

const CardSubtitle = styled(Typography)(() => ({
  maxWidth: '300px',
}));

const CardSuccess = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 600,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.pxToRem(24),
  },
}));

function isNetworkError(error: Error) {
  return [
    'Failed to fetch', // Chrome
    'NetworkError when attempting to fetch resource.', // Firefox
    'The Internet connection appears to be offline.', // Safari
  ].includes(error.message);
}

// Terminal API Mutation
const REGISTER_FOR_PROMOTION_MUTATION = gql`
  mutation RegisterForPromotion($input: RegisterForPromotionInput!) {
    registerForPromotion(input: $input)
  }
`;
// #endregion Terminal GraphQL

// An _extremely_ basic email sanity check regex.
const EMAIL_REGEX = /.+@.+\..+/;

const SignupForm: React.FC<SignupFormProps> = ({ content }) => {
  const theme = useTheme();

  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const captchaRef = useRef<ReCAPTCHA>(null);

  const formError = useCallback((error: string) => {
    setErrorMessage(error);
    setSubmitting(false);
  }, []);

  const [register] = useMutation(REGISTER_FOR_PROMOTION_MUTATION, {
    onCompleted: () => {
      setSuccess(true);
      setSubmitting(false);
    },
    onError: (error) => {
      const errMsg = isNetworkError(error)
        ? content.errors.network
        : error.message;
      formError(errMsg);
    },
  });

  const handleSubmit = async () => {
    if (success || submitting) return;

    setSubmitting(true);
    setErrorMessage(null);

    if (!email) {
      formError(content.errors.email);
      return;
    }

    if (!EMAIL_REGEX.test(email)) {
      formError(content.errors.email);
      return;
    }

    const token = await captchaRef.current?.executeAsync();
    if (!token) {
      formError(content.errors.captcha);
      return;
    }

    await register({
      variables: {
        input: {
          captchaToken: token,
          email,
          promotionId: 'dettydecember2024',
        },
      },
    });
  };

  return (
    <Container>
      <Col xs={12}>
        <Wrapper>
          <Card
            bgColor={theme.palette.common.white}
            shadowColor={theme.palette.grey[400]}
          >
            <CardTitle>{content.title}</CardTitle>

            <FormWrapper>
              <Form>
                <FormInput
                  disabled={submitting}
                  name="email"
                  type="email"
                  label={content.prompt}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setSuccess(false);
                  }}
                  onSubmit={handleSubmit}
                  errorMessage={errorMessage || undefined}
                />

                <ReCAPTCHA
                  ref={captchaRef}
                  size="invisible"
                  badge="bottomleft"
                  sitekey={RECAPTCHA_SITE_KEY}
                />
              </Form>
            </FormWrapper>

            {success ? (
              <CardSuccess>{content.success}</CardSuccess>
            ) : (
              <Button onClick={handleSubmit} disabled={submitting}>
                {content.button}
              </Button>
            )}

            <CardSubtitle>{content.subtitle}</CardSubtitle>
          </Card>
        </Wrapper>
      </Col>
    </Container>
  );
};

export interface SignupFormProps {
  content: {
    title: string;
    prompt: string;
    button: string;
    subtitle: string;
    success: string;
    errors: {
      email: string;
      captcha: string;
      network: string;
    };
  };
}

const query = graphql`
  fragment FormDettyDecember on DettyDecemberYaml {
    form {
      title
      prompt
      button
      subtitle
      success
      errors {
        email
        captcha
        network
      }
    }
  }
`;

export { query, SignupForm };
