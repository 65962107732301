/*
 * Copyright (C) 2024 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import { DownloadSection } from 'components/contents/Homepage/DownloadSection';
import {
  Hero,
  HeroProps,
} from 'components/contents/promotions/DettyDecember/Hero';
import {
  Promoters,
  PromotersProps,
} from 'components/contents/promotions/DettyDecember/Promoters';
import {
  SignupForm,
  SignupFormProps,
} from 'components/contents/promotions/DettyDecember/SignupForm';
import { DownloadProps } from 'components/partials/Download';
import Layout from 'components/partials/Layout';
import { Breakpoints } from 'utils/enums';

// Styles
const HeroSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(5),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: theme.spacing(28),
  },
}));

const FormSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(10),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: theme.spacing(28),
  },
}));

const PromotersSection = styled('section')(({ theme }) => ({
  margin: theme.spacing(10, 0, 15),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    margin: theme.spacing(28, 0, 40),
  },
}));

// Types
interface Props {
  data: {
    downloadYaml: DownloadProps;
    dettyDecemberYaml: {
      metadata: MetaData;
      downloadOverrides: Partial<DownloadProps['content']>;
      hero: HeroProps['content'];
      form: SignupFormProps['content'];
      promoters: PromotersProps['content'];
    };
  };
}

const Home: React.FC<Props> = ({ data }) => {
  const { downloadOverrides, metadata } = data.dettyDecemberYaml;
  return (
    <Layout title={metadata.title} description={metadata.description}>
      <HeroSection>
        <Hero content={data.dettyDecemberYaml.hero} />
      </HeroSection>

      <FormSection>
        <SignupForm content={data.dettyDecemberYaml.form} />
      </FormSection>

      <PromotersSection>
        <Promoters content={data.dettyDecemberYaml.promoters} />
      </PromotersSection>

      <DownloadSection
        showLinkToBeta={false}
        content={{
          content: {
            ...data.downloadYaml.content,
            ...downloadOverrides,
          },
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query DettyDecemberQuery {
    downloadYaml {
      ...DownloadContent
    }
    dettyDecemberYaml {
      metadata {
        title
        description
      }
      downloadOverrides {
        title
        text
        images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      ...HeroDettyDecember
      ...FormDettyDecember
      ...PromotersDettyDecember
    }
  }
`;

export default Home;
